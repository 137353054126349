












import { Component, Vue } from "vue-property-decorator";
import importStatusWrapper from "@/components/knockoutWrappers/ImportStatusWrapper.vue";

@Component({
  components: {
    importStatusWrapper,
  },
})
export default class ImportStatus extends Vue {}
